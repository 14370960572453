import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ALLOWED_MODULES } from "../constants/enums";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { goodsReceivingNoteRoutes } from "../views/GoodsReceivingNote/GoodsReceivingNote.router";
import { BeforeProfile } from "../views/Profile/UsersList/UsersList";
import { purchaseOrderRoutes } from "../views/PurchaseOrder/PurchaseOrder.router";
import { quotationsRoutes } from "../views/Quotations/Quotations.router";
import { saleOrderRoutes } from "../views/SaleOrder/SaleOrder.router";
import { securityRoutes } from "../views/Security/Security.router";
import { OOPSError } from "../views/authentication/OopsError";
import { appProjectRoutes } from "../views/project/Project.router";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";
import { creditDebitNodeRoutes } from "./routes/CreditDebitNode.router";
import { proformaInvoiceRoutes } from "./routes/ProformaInvoice.router";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() =>
    import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
      default: FullLayout,
    })),
  ),
);
const BlankLayout = Loadable(
  lazy(() => import("../layout/blankLayout/BlankLayout")),
);
/* ***End Layouts**** */

const Error = Loadable(
  lazy(() =>
    import("../views/authentication/Error").then(({ Error }) => ({
      default: Error,
    })),
  ),
);

/* ****Pages***** */

const Dashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
      default: Dashboard,
    })),
  ),
);

const Login = Loadable(
  lazy(() =>
    import("../views/authentication/Login").then(({ Login }) => ({
      default: Login,
    })),
  ),
);
const AccessDenied = Loadable(
  lazy(() =>
    import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
      default: AccessDenied,
    })),
  ),
);

const ManageUser = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/ManageUser").then(({ ManageUser }) => ({
      default: ManageUser,
    })),
  ),
);

const ViewProfile = Loadable(
  lazy(() =>
    import("../views/Profile/ViewProfile/ViewProfile").then(
      ({ ViewProfile }) => ({
        default: ViewProfile,
      }),
    ),
  ),
);

const SecurityDashboard = Loadable(
  lazy(() =>
    import("../views/SecurityOld/CreateSecurityGroup").then(
      ({ CreateSecurityGroup }) => ({ default: CreateSecurityGroup }),
    ),
  ),
);

const ListSecurityGroups = Loadable(
  lazy(() =>
    import("../views/SecurityOld/ListSecurityGroups").then(
      ({ ListSecurityGroups }) => ({ default: ListSecurityGroups }),
    ),
  ),
);

//************************ Inventory ***************************/
const InventoryInList = Loadable(
  lazy(() =>
    import("../views/inventory/InventoryIn/InventoryInList").then(
      ({ InventoryIn }) => ({
        default: InventoryIn,
      }),
    ),
  ),
);
const ManageInventoryIn = Loadable(
  lazy(() =>
    import("../views/inventory/InventoryIn/ManageInventoryIn").then(
      ({ ManageInventoryIn }) => ({
        default: ManageInventoryIn,
      }),
    ),
  ),
);

//************************ Ledger ***************************/
const LedgerList = Loadable(
  lazy(() =>
    import("../views/inventory/Ledger/LedgerList").then(({ LedgerList }) => ({
      default: LedgerList,
    })),
  ),
);

//************************ Products ***************************/
const Products = Loadable(
  lazy(() =>
    import("../views/products/Products").then(({ Products }) => ({
      default: Products,
    })),
  ),
);

const ManageProduct = Loadable(
  lazy(() =>
    import("../views/products/ManageProduct").then(({ ManageProduct }) => ({
      default: ManageProduct,
    })),
  ),
);

//************************ Categories ***************************/
const CategoryList = Loadable(
  lazy(() =>
    import("../views/category/CategoryList").then(({ CategoryList }) => ({
      default: CategoryList,
    })),
  ),
);
const ManageCategory = Loadable(
  lazy(() =>
    import("../views/category/ManageCategory").then(({ ManageCategory }) => ({
      default: ManageCategory,
    })),
  ),
);

//************************************************ Invoices Imports ***************************************************/
const InvoiceList = Loadable(
  lazy(() =>
    import("../views/quotes/InvoiceList").then(({ InvoiceList }) => ({
      default: InvoiceList,
    })),
  ),
);

const ManageInvoice = Loadable(
  lazy(() =>
    import("../views/quotes/ManageInvoice").then(({ ManageInvoice }) => ({
      default: ManageInvoice,
    })),
  ),
);

const QuotesInvoice = Loadable(
  lazy(() =>
    import("../publicModules/QuotesInvoice/QuotesInvoice").then(
      ({ QuotesInvoice }) => ({
        default: QuotesInvoice,
      }),
    ),
  ),
);

//************************************************ Delivery Challa Imports ***************************************************/
const DeliveryChallanList = Loadable(
  lazy(() =>
    import("../views/deliveryChallan/DeliveryChallanList").then(
      ({ DelvieryChallanList }) => ({
        default: DelvieryChallanList,
      }),
    ),
  ),
);

const ManageDeliveryChallan = Loadable(
  lazy(() =>
    import("../views/deliveryChallan/ManageDeliveryChallan").then(
      ({ ManageDeliveryChallan }) => ({
        default: ManageDeliveryChallan,
      }),
    ),
  ),
);

//************************ Customer Imports ***************************/
const CustomersList = Loadable(
  lazy(() =>
    import("../views/customers/CustomersList").then(({ CustomersList }) => ({
      default: CustomersList,
    })),
  ),
);
const BankDetailsList = Loadable(
  lazy(() =>
    import("../views/BankDetails/BankDetailsList").then(
      ({ BankDetailsList }) => ({
        default: BankDetailsList,
      }),
    ),
  ),
);
const ManageCustomer = Loadable(
  lazy(() =>
    import("../views/customers/ManageCustomer").then(({ ManageCustomer }) => ({
      default: ManageCustomer,
    })),
  ),
);

//************************ Contact Imports ***************************/
const ContactsList = Loadable(
  lazy(() =>
    import("../views/contacts/ContactsList").then(({ ContactsList }) => ({
      default: ContactsList,
    })),
  ),
);
const ManageContact = Loadable(
  lazy(() =>
    import("../views/contacts/ManageContact").then(({ ManageContact }) => ({
      default: ManageContact,
    })),
  ),
);
//************************ Billing Company Imports ***************************/
const BillingCompanyList = Loadable(
  lazy(() =>
    import("../views/billingCompany/BillingCompanyList").then(
      ({ BillingCompanyList }) => ({
        default: BillingCompanyList,
      }),
    ),
  ),
);
const ManageBillingCompany = Loadable(
  lazy(() =>
    import("../views/billingCompany/ManageBillingCompany").then(
      ({ ManageBillingCompany }) => ({
        default: ManageBillingCompany,
      }),
    ),
  ),
);
//************************ Vendor Imports ***************************/
const VendorList = Loadable(
  lazy(() =>
    import("../views/vendor/VendorList").then(({ VendorList }) => ({
      default: VendorList,
    })),
  ),
);
const VendorDetailsWithTabs = Loadable(
  lazy(() =>
    import("../views/vendor/VendorDetailsWithTabs").then(
      ({ VendorDetailsWithTabs }) => ({
        default: VendorDetailsWithTabs,
      }),
    ),
  ),
);
const ManageVendor = Loadable(
  lazy(() =>
    import("../views/vendor/ManageVendor").then(({ ManageVendor }) => ({
      default: ManageVendor,
    })),
  ),
);
const ForgotPassword = Loadable(
  lazy(() =>
    import("../views/authentication/ForgotPassword").then(
      ({ ForgotPassword }) => ({
        default: ForgotPassword,
      }),
    ),
  ),
);
// debit note
const DebitNoteList = Loadable(
  lazy(() =>
    import("../views/debitNote/DebitNoteList").then(({ DebitNoteList }) => ({
      default: DebitNoteList,
    })),
  ),
);
const ManageDebitNote = Loadable(
  lazy(() =>
    import("../views/debitNote/ManageDebitNote").then(
      ({ ManageDebitNote }) => ({
        default: ManageDebitNote,
      }),
    ),
  ),
);

export const Router = [
  {
    path: "/",

    element: (
      <ProtectedRoute>
        <FullLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <Dashboard />
          </AuthorizedRoute>
        ),
      },

      //************************ Inventory Routes ***************************/
      {
        path: "/inventory",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.INVENTORY]}>
            <InventoryInList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/inventory/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.INVENTORY]}>
            <ManageInventoryIn />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/inventory/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.INVENTORY]}>
            <ManageInventoryIn />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/inventory/duplicate/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.INVENTORY]}>
            <ManageInventoryIn isDuplicate={true} />
          </AuthorizedRoute>
        ),
      },

      //************************ Ledger Routes ***************************/
      {
        path: "/ledger",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.LEDGER]}>
            <LedgerList />
          </AuthorizedRoute>
        ),
      },

      //************************ Category Routes ***************************/
      {
        path: "/category",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CATEGORY]}>
            <CategoryList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/category/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CATEGORY]}>
            <ManageCategory />
          </AuthorizedRoute>
        ),
      },

      //************************ Product Routes ***************************/
      {
        path: "/products",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PRODUCTS]}>
            <Products />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/products/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PRODUCTS]}>
            <ManageProduct />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/products/manage/:id",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PRODUCTS]}>
            <ManageProduct />
          </AuthorizedRoute>
        ),
      },

      /************************ Invoices Routes ************************/
      {
        path: "/invoices",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.INVOICE]}>
            <InvoiceList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoices/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.INVOICE]}>
            <ManageInvoice />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoices/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.INVOICE]}>
            <ManageInvoice />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoices/duplicate/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.INVOICE]}>
            <ManageInvoice isDuplicate={true} />
          </AuthorizedRoute>
        ),
      },

      /************************ Delivery challan Routes ************************/
      {
        path: "/delivery-challan",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.DELIVERY_CHALLAN]}>
            <DeliveryChallanList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/delivery-challan/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.DELIVERY_CHALLAN]}>
            <ManageDeliveryChallan />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/delivery-challan/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.DELIVERY_CHALLAN]}>
            <ManageDeliveryChallan />
          </AuthorizedRoute>
        ),
      },

      /************************ Customer Routes ************************/
      {
        path: "/customers",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CUSTOMERS]}>
            <CustomersList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/customers/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CUSTOMERS]}>
            <ManageCustomer isBillingCompany={0} />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/customers/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CUSTOMERS]}>
            <ManageCustomer isBillingCompany={0} />
          </AuthorizedRoute>
        ),
      },

      /************************ Bank Details************************/
      {
        path: "/bank-details",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.BANK_DETAILS]}>
            <BankDetailsList />
          </AuthorizedRoute>
        ),
      },
      /************************ Contact Routes ************************/
      {
        path: "/contacts",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CONTACTS]}>
            <ContactsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/contacts/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CONTACTS]}>
            <ManageContact />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/contacts/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CONTACTS]}>
            <ManageContact />
          </AuthorizedRoute>
        ),
      },

      /******************* Billing company Routes ********************/
      {
        path: "/billing-company",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.BANK_DETAILS]}>
            <BillingCompanyList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/billing-company/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.BANK_DETAILS]}>
            <ManageCustomer isBillingCompany={1} />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/billing-company/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.BANK_DETAILS]}>
            <ManageCustomer isBillingCompany={1} />
          </AuthorizedRoute>
        ),
      },
      /******************* Vendor Routes ********************/
      {
        path: "/vendor",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.VENDOR]}>
            <VendorList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/vendor/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.VENDOR]}>
            <ManageVendor />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/vendor/manage/:vendorUUID",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.VENDOR]}>
            <VendorDetailsWithTabs />
          </AuthorizedRoute>
        ),
      },
      /******************* Debit Note********************/
      {
        path: "/debit-note",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CREDIT_DEBIT_NOTE]}>
            <DebitNoteList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/debit-note/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CREDIT_DEBIT_NOTE]}>
            <ManageDebitNote />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/debit-note/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CREDIT_DEBIT_NOTE]}>
            <ManageDebitNote />
          </AuthorizedRoute>
        ),
      },

      /************************ Profile ***************************/

      {
        path: "/view-profile",
        exact: true,
        element: (
          // <AuthorizedRoute allowedRoles={["ADMIN"]}>
          <ViewProfile />
          // </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.USERS]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.USERS]}>
            <BeforeProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage/:userId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[ALLOWED_MODULES.USERS]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },

      // {
      //   path: "/list-security-groups",
      //   exact: true,
      //   element: (
      //     // <AuthorizedRoute allowedRoles={["ADMIN"]}>
      //     <ListSecurityGroups />
      //     // </AuthorizedRoute>
      //   ),
      // },

      // {
      //   path: "/create-security-group",
      //   exact: true,
      //   element: (
      //     // <AuthorizedRoute allowedRoles={["ADMIN"]}>
      //     <SecurityDashboard />
      //     // </AuthorizedRoute>
      //   ),
      // },

      { path: "access", element: <AccessDenied /> },
      { path: "error", element: <OOPSError /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/", element: <Navigate to="/dashboard" /> },
    ]
      .concat(creditDebitNodeRoutes)
      .concat(proformaInvoiceRoutes)
      .concat(goodsReceivingNoteRoutes)
      .concat(purchaseOrderRoutes)
      .concat(saleOrderRoutes)
      .concat(quotationsRoutes)
      .concat(securityRoutes)
      .concat(appProjectRoutes),
  },

  // --------------------------------TASK ROUTING----------------------------------------------

  {
    path: "/auth",
    element: (
      <PublicRoute>
        <BlankLayout />
      </PublicRoute>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "reset-password", element: <ForgotPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/public/invoices/:uuid",
    element: <QuotesInvoice />,
  },
];
