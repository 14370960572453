import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IDebitNote } from "./debitNote.types";

//=============================== Fetch Multiple Credit/Debit Nodes ===============================
export const FETCH_DEBIT_NOTE_LIST_PROGRESS = "FETCH_DEBIT_NOTE_LIST_PROGRESS";
export const FETCH_DEBIT_NOTE_LIST_SUCCESS = "FETCH_DEBIT_NOTE_LIST_SUCCESS";
export const FETCH_DEBIT_NOTE_LIST_FAILED = "FETCH_DEBIT_NOTE_LIST_FAILED";

export const fetchDebitNoteListProgress = () =>
  action(FETCH_DEBIT_NOTE_LIST_PROGRESS);
export const fetchDebitNoteListSuccess = (
  list: IDebitNote[],
  totalRecords: number,
) => action(FETCH_DEBIT_NOTE_LIST_SUCCESS, { list, totalRecords });
export const fetchDebitNoteListFailed = () =>
  action(FETCH_DEBIT_NOTE_LIST_FAILED);

export const fetchDebitNoteListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchDebitNoteListProgress());

      const res = await api.get(
        `/credit_debit_note/get-debit-note${searchQuery}`,
      );
      const data: IDebitNote[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchDebitNoteListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchDebitNoteListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Debit Node ===============================
export const FETCH_SINGLE_DEBIT_NOTE_PROGRESS =
  "FETCH_SINGLE_DEBIT_NOTE_PROGRESS";
export const FETCH_SINGLE_DEBIT_NOTE_SUCCESS =
  "FETCH_SINGLE_DEBIT_NOTE_SUCCESS";
export const FETCH_SINGLE_DEBIT_NOTE_FAILED = "FETCH_SINGLE_DEBIT_NOTE_FAILED";

export const fetchDebitNoteProgress = () =>
  action(FETCH_SINGLE_DEBIT_NOTE_PROGRESS);
export const fetchDebitNoteSuccess = (data: IDebitNote) =>
  action(FETCH_SINGLE_DEBIT_NOTE_SUCCESS, { data });
export const fetchDebitNoteFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_DEBIT_NOTE_FAILED, { errorMessage });

export const fetchDebitNoteAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDebitNoteProgress());
      const res = await api.get(
        `/credit_debit_note/get-debit-note?debit_note_uuid=${uuid}`,
      );
      const data: IDebitNote[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchDebitNoteSuccess(data[0]));
      } else {
        dispatch(fetchDebitNoteFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchDebitNoteFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Single Credit/Debit Node  ===============================
export const upsertDebitNoteAsync =
  (
    payload: IDebitNote,
    onCallback: (isSuccess: boolean, invoiceUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const {
        is_round_off,
        create_ts,
        insert_ts,
        combined_debit_note_no,
        ...rest
      } = payload;

      const res = await api.post("/credit_debit_note/upsert-debit-note", rest);

      onCallback(true, res.data.data.invoice_uuid);
      dispatch(
        showMessage({
          type: "success",
          message: "Invoice saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchDebitNotePreviewAsync =
  (
    uuid: string,
    isIndianStyle: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/credit_debit_note/get-debit-note-preview?debit_note_uuid=${uuid}&isPreview=true&IndianStyle=${isIndianStyle}`,
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

export const fetchDebitNoteDownloadPdfAsync =
  (
    uuid: string,
    isIndianStyle: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/credit_debit_note/get-debit-note-preview?debit_note_uuid=${uuid}&isPreview=false&IndianStyle=${isIndianStyle}`,
        {
          responseType: "arraybuffer",
        },
      );

      dispatch(saveLoaderCompleted());
      // Convert the PDF content into a Blob
      const blob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

//=============================== Clear State ===============================
export const CLEAR_SINGLE_DEBIT_NOTE = "CLEAR_SINGLE_DEBIT_NOTE";
export const CLEAR_DEBIT_NOTE_STATE = "CLEAR_DEBIT_NOTE_STATE";
export const clearDebitNote = () => action(CLEAR_SINGLE_DEBIT_NOTE);
export const clearDebitNoteState = () => action(CLEAR_DEBIT_NOTE_STATE);
