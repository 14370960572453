import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IGoodsReceivingNote } from "./goods-receiving-note.types";

//=============================== Goods Receiving Note List ===============================
export const FETCH_GRN_LIST_PROGRESS = "FETCH_GRN_LIST_PROGRESS";
export const FETCH_GRN_LIST_SUCCESS = "FETCH_GRN_LIST_SUCCESS";
export const FETCH_GRN_LIST_FAILED = "FETCH_GRN_LIST_FAILED";

export const fetchGRNListProgress = () => action(FETCH_GRN_LIST_PROGRESS);
export const fetchGRNListSuccess = (
  list: IGoodsReceivingNote[],
  totalRecords: number,
) => action(FETCH_GRN_LIST_SUCCESS, { list, totalRecords });
export const fetchGRNListFailed = () => action(FETCH_GRN_LIST_FAILED);

export const fetchGoodsReceivingNoteListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchGRNListProgress());

      const res = await api.get(`/grn/get-grn${searchQuery}&status=ACTIVE`);
      const data: IGoodsReceivingNote[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchGRNListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchGRNListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Goods Receiving Note ===============================
export const FETCH_SINGLE_GRN_PROGRESS = "FETCH_SINGLE_GRN_PROGRESS";
export const FETCH_SINGLE_GRN_SUCCESS = "FETCH_SINGLE_GRN_SUCCESS";
export const FETCH_SINGLE_GRN_FAILED = "FETCH_SINGLE_GRN_FAILED";

export const fetchSingleGRNProgress = () => action(FETCH_SINGLE_GRN_PROGRESS);
export const fetchSingleGRNSuccess = (data: IGoodsReceivingNote) =>
  action(FETCH_SINGLE_GRN_SUCCESS, { data });
export const fetchSingleGRNFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_GRN_FAILED, { errorMessage });

export const fetchSingleGoodsReceivingNoteAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleGRNProgress());
      const res = await api.get(`/grn/get-grn?grn_uuid=${uuid}`);
      const data: IGoodsReceivingNote[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleGRNSuccess(data[0]));
      } else {
        dispatch(fetchSingleGRNFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchSingleGRNFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Single Goods Receiving Note ===============================
export const upsertSingleGoodsReceivingNoteAsync =
  (
    payload: IGoodsReceivingNote,
    onCallback: (isSuccess: boolean, grnUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const {
        create_ts,
        insert_ts,
        combined_grn_no,
        purchase_invoice_no,
        ...restPayload
      } = payload;
      const res = await api.post("/grn/upsert-grn", restPayload);

      const responseData: IGoodsReceivingNote = res.data.data;

      onCallback(true, responseData.grn_uuid as string);
      dispatch(
        showMessage({
          type: "success",
          message: "GRN saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Delete Single  Goods Receiving Note  ===============================
export const deleteSingleGoodsReceivingNoteAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.delete("/grn/delete-grn", {
        data: { grn_uuid: uuid },
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Goods Receiving Note deleted successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Clear State ===============================
export const CLEAR_SINGLE_GRN = "CLEAR_SINGLE_GRN";
export const CLEAR_GRN_STATE = "CLEAR_GRN_STATE";
export const clearSingleGoodsReceivingNoteSync = () => action(CLEAR_SINGLE_GRN);
export const clearSingleGoodsReceivingNoteStateSync = () =>
  action(CLEAR_GRN_STATE);
