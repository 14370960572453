import { LoadState } from "../../constants/enums";
import {
  IGoodsReceivingNote,
  IGoodsReceivingNoteState,
  IGRNItem,
} from "./goods-receiving-note.types";

export const defaultGrnItem: IGRNItem = {
  product_uuid: null,
  product_name: null,
  product_type: null,
  description: null,
  item_type: null,
  HSN: null,
  quantity: 0,
  rate: 0,
  unit: null,
  other_unit: "",
  discount: 0,
  discount_amount: 0,
  cess_rate: 0,
  cess_amount: 0,
  taxable_amount: 0,
  tax_rate: 0,
  tax_amount: 0,
  tax_type: "IGST",
  amount: 0,
};

export const defaultGoodsReceivingNote: IGoodsReceivingNote = {
  grn_uuid: null,
  grn_type: null,
  grn_no: "",
  grn_date: null,
  po_number: null,
  po_date: null,
  lr_no: null,
  delivery_type: "",
  dispatch_through: null,
  transport_id: null,
  transport_doc_no: null,
  transport_doc_date: null,
  vehicle_no: null,
  place_of_supply: null,
  billing_company_uuid: null,
  billing_company_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_name: null,
  billing_company_branch_state: null,
  vendor_uuid: "",
  vendor_name: "",
  registration_type: "",
  vendor_gstin: "",
  vendor_address_line1: null,
  vendor_address_line2: null,
  vendor_address_city: null,
  vendor_address_state: null,
  vendor_address_pincode: null,
  vendor_address_country: null,
  delivery_address_name: null,
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  contact_uuid: null,
  contact_name: null,
  phone_number: null,
  remark: null,
  invoice_items: [defaultGrnItem],
  total_amount: 0,
  is_round_off: 0,
  term_and_condition: null,
  total_amount_after_tax: 0,
  status: "ACTIVE",
};

export const defaultGoodsReceivingNoteState: IGoodsReceivingNoteState = {
  grn_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  single_grn: {
    data: defaultGoodsReceivingNote,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
