import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IPreviewType } from "../../views/preview/StandardPreview.types";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IDeliveryChallan } from "./deliveryChallan.types";

//========================= Delivery Challan List =========================
export const FETCH_DELIVERY_CHALLAN_LIST_PROGRESS =
  "FETCH_DELIVERY_CHALLAN_LIST_PROGRESS";
export const FETCH_DELIVERY_CHALLAN_LIST_SUCCESS =
  "FETCH_DELIVERY_CHALLAN_LIST_SUCCESS";
export const FETCH_DELIVERY_CHALLAN_LIST_FAILED =
  "FETCH_DELIVERY_CHALLAN_LIST_FAILED";

export const fetchDeliveryChallanListProgress = () =>
  action(FETCH_DELIVERY_CHALLAN_LIST_PROGRESS);
export const fetchDeliveryChallanListSuccess = (
  list: IDeliveryChallan[],
  totalRecords: number,
) => action(FETCH_DELIVERY_CHALLAN_LIST_SUCCESS, { list, totalRecords });
export const fetchDeliveryChallanListFailed = () =>
  action(FETCH_DELIVERY_CHALLAN_LIST_FAILED);

export const fetchDeliveryChallanListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchDeliveryChallanListProgress());

      const res = await api.get(
        `/delivery_challan/get-delivery-challan${searchQuery}`,
      );
      const data: IDeliveryChallan[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchDeliveryChallanListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchDeliveryChallanListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Quote ===============================
export const FETCH_DELIVERY_CHALLAN_PROGRESS =
  "FETCH_DELIVERY_CHALLAN_PROGRESS";
export const FETCH_DELIVERY_CHALLAN_SUCCESS = "FETCH_DELIVERY_CHALLAN_SUCCESS";
export const FETCH_DELIVERY_CHALLAN_FAILED = "FETCH_DELIVERY_CHALLAN_FAILED";

export const fetchDeliveryChallanProgress = () =>
  action(FETCH_DELIVERY_CHALLAN_PROGRESS);
export const fetchDeliveryChallanSuccess = (data: IDeliveryChallan) =>
  action(FETCH_DELIVERY_CHALLAN_SUCCESS, { data });
export const fetchDeliveryChallanFailed = (errorMessage: string) =>
  action(FETCH_DELIVERY_CHALLAN_FAILED, { errorMessage });

export const fetchDeliveryChallanAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDeliveryChallanProgress());
      const res = await api.get(
        `/delivery_challan/get-delivery-challan?delivery_challan_uuid=${uuid}`,
      );
      const data: IDeliveryChallan[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchDeliveryChallanSuccess(data[0]));
      } else {
        dispatch(
          fetchDeliveryChallanFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchDeliveryChallanFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert DeliveryChallan ===============================
export const upsertDeliveryChallanAsync =
  (
    data: IDeliveryChallan,
    file: any | null,
    onCallback: (isSuccess: boolean, invoiceUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const {
        create_ts,
        insert_ts,
        combined_delivery_challan_no,
        sales_invoice_no,
        ...payload
      } = data;
      const res = await api.post("/delivery_challan/upsert-delivery-challan", {
        ...payload,
      });

      onCallback(true, res.data.data.delivery_challan_uuid);
      dispatch(
        showMessage({
          type: "success",
          message: "Delivery Challan saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Preview DeliveryChallan ===============================
export const fetchDeliveryChallanPreviewAsync =
  (
    uuid: string,
    isIndianStyle: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/delivery_challan/get-delivery-challan-preview?delivery_challan_uuid=${uuid}&isPreview=true&IndianStyle=${isIndianStyle}`,
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

export const fetchDeliveryChallanDownloadPdfAsync =
  (
    uuid: string,
    isIndianStyle: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/delivery_challan/get-delivery-challan-preview?delivery_challan_uuid=${uuid}&isPreview=false&IndianStyle=${isIndianStyle}`,
        {
          responseType: "arraybuffer",
        },
      );

      dispatch(saveLoaderCompleted());
      // Convert the PDF content into a Blob
      const blob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

//=============================== Clear State ===============================
export const CLEAR_DELIVERY_CHALLAN = "CLEAR_DELIVERY_CHALLAN";
export const CLEAR_DELIVERY_CHALLAN_STATE = "CLEAR_DELIVERY_CHALLAN_STATE";
export const clearDeliveryChallan = () => action(CLEAR_DELIVERY_CHALLAN);
export const clearDeliveryChallanState = () =>
  action(CLEAR_DELIVERY_CHALLAN_STATE);
